import styled, { css } from "styled-components";
import Div from "components/Div";
import { Section, SectionHeader, sectionPadding } from "components/common";
import {
  CreditCardBadge,
  ButtonPrimary,
  Subheader,
  Link,
  P,
  H1,
  Footnote,
  Icon,
  CountryPhone,
  H2,
  H3,
  H4,
  Small,
  GridRow,
} from "notes";
import { useState } from "react";
import ShoutoutForm from "components/common/ShoutoutForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { NameInput } from "components/common/formikInputs";
import { useContext } from "react";
import { CheckoutContext } from ".";
import { DateTime, Duration } from "luxon";
import Countdown from "components/common/Countdown";
import { TimeContext } from "components/TimeProvider";
import { ReactComponent as IconVideoChat } from "assets/IconVideoChat.svg";
import { ReactComponent as IconShopping } from "assets/IconShopping.svg";
import { formatCurrency } from "components/formatCurrency";
import firebase from "firebase";
import { useDocument, useDocumentData } from "react-firebase-hooks/firestore";
import { useLoginLink } from "components/useLoginLink";
import parsePhoneNumber from "libphonenumber-js";

const BackgroundImage = styled(IconVideoChat)`
  color: ${(props) => props.theme.palette.gray.lighter};
  position: absolute;
  top: 0;
  left: 0;
  height: 490px;
  width: 460px;
  opacity: 0.12;
  transform: rotate(-40deg);
  z-index: 0;
`;

const Content = styled(Div)`
  ${sectionPadding};
  position: relative;
  z-index: 1;
  ${H1} {
    display: flex;
    align-items: center;
    font-family: "Overpass";
    font-size: 32px;
    font-weight: 800;
    text-transform: none;
    margin: 0 0 24px 0;
    svg {
      margin-right: 16px;
      width: 40px;
      height: 40px;
      path {
        fill: #222222;
      }
    }
  }
  ${Small} {
    color: ${(props) => props.theme.palette.gray.primary};
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    margin-bottom: 12px;
  }
  & > ${H3} {
    display: flex;
    font-weight: 800;
    margin: 0;
  }
  ${Subheader} {
    font-size: 17px;
    line-height: 22px;
    margin-top: 24px;
    margin-bottom: 8px;
  }
  p {
    margin: 0;
  }
  ${P} {
    span {
      font-weight: 600;
    }
  }
  @media only screen and ${(props) => props.theme.media.mobile} {
    padding: 24px;
    ${H1} {
      font-size: 25px;
      line-height: 32px;
      svg {
        width: 24px;
        height: 24px;
      }
    }
    & > ${H3} {
      font-size: 20px;
      line-height: 26px;
      & > svg {
        display: none;
      }
    }
    ${Subheader} {
      font-size: 15px;
      line-height: 19px;
      margin-top: 16px;
    }
  }
`;

const ContentMargins = styled(Content)`
  p + p {
    margin-top: ${(props) => (props.large ? "12px" : "4px")};
  }
  @media only screen and ${(props) => props.theme.media.mobile} {
    ${P} {
      font-size: 15px;
      line-height: 19px;
    }
    ${Footnote} {
      font-size: 11px;
      line-height: 14px;
    }
  }
`;

const Wrapper = styled(Section)`
  padding: 0 !important;
  border: none;
  margin-top: 24px;
  overflow: hidden;
  ${(props) =>
    props.isRequestRejected &&
    css`
      margin-top: -100px;
    `}
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 0;
    padding: 0 !important;
    border-radius: 0;
    &:first-of-type {
      ${BackgroundImage} {
          top: -62px;
          left: -74px;
        }
      ${Content} {
        padding-bottom: 40px;

      }
    }
    &:not(:last-of-type):after {
      display: block;
      content: "";
      height: 4px;
      width: 100%;
      position: relative;
      z-index: 3;
      background-color: #FAFAFA;
      box-shadow: inset 0 2px 6px 0 rgba(0,0,0,0.24);
    }
  }
`;

const Button = styled(ButtonPrimary)`
  justify-content: space-between;
  margin-top: 40px;
  padding: 9px 24px 9px 16px;
  width: 392px;
  height: 56px;
  svg {
    width: 21px;
    height: 21px;
    path {
      fill: #ffffff;
    }
  }
  span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    span {
      font-size: 12px;
      font-weight: 300;
      line-height: 15px;
    }
  }
  &:disabled {
    border: 2px solid ${(props) => props.theme.palette.gray.lighter};
    svg path {
      fill: ${(props) => props.theme.palette.gray.lighter};
    }
  }
  @media all and ${(props) => props.theme.media.mobile} {
    width: 100%;
  }
`;

const WarningIcon = styled(Div)`
  color: ${(props) => props.theme.colors.orange40};
`;

const Confirmation = () => {
  const {
    event,
    timeslots,
    firstName,
    lastName,
    name,
    amount,
    price,
    fee,
    setName,
    friend,
    setFriend,
    email,
    setEmail,
    phone,
    setPhone,
    consent,
    setConsent,
    timeslot,
    setTimeslot,
    reservation,
  } = useContext(CheckoutContext);
  const { time } = useContext(TimeContext);
  const [editPhone, setEditPhone] = useState(false);
  const phoneNumber = phone ? parsePhoneNumber(phone).formatNational() : "";

  const [transaction, l1, e1]: any = useDocumentData(
    firebase
      .firestore()
      .doc(
        `/meet_greet/${reservation?.sessionId}/slots/${reservation?.id}/private/data`
      )
  );

  const [slot, l2, e2]: any = useDocumentData(
    firebase
      .firestore()
      .doc(`/meet_greet/${reservation?.sessionId}/slots/${reservation?.id}`)
  );

  const [roomDoc, l3, e3]: any = useDocument(
    firebase
      .firestore()
      .doc(`/meet_greet/${reservation?.sessionId}/rooms/${slot?.recipientId}`)
  );

  const room = roomDoc?.data();

  if (l1 || l2 || l3) {
    return null;
  }

  if (e1 || e2 || e3) {
    console.error(e1, e2, e3);
  }

  const savePhone = () => {
    roomDoc?.ref?.update({ phone });
    setEditPhone(false);
  };

  const selectedTimeslot = timeslots?.find((t) => t.id === timeslot);
  const formattedTime = DateTime.fromJSDate(
    selectedTimeslot?.startDate?.toDate()
  );
  const timeToStart =
    formattedTime &&
    selectedTimeslot &&
    Duration.fromMillis((formattedTime as any) - time).shiftTo(
      "days",
      "hours",
      "minutes",
      "seconds",
      "milliseconds"
    );

  return (
    <>
      <Wrapper c_text positionRelative>
        <Div>
          <Content alignCenter dflexColumn>
            <Div alignCenter>
              <H1>
                <Icon form name="VideoChat" />
                Meet &amp; Greet {time < formattedTime ? "Booked" : "Started"}!
              </H1>
            </Div>
            {time < formattedTime && (
              <>
                <Small>Call Starts In</Small>
                <Countdown timeToStart={timeToStart} />
              </>
            )}
            {!friend &&
              Duration.fromMillis((formattedTime as any) - time).shiftTo(
                "minutes"
              ).minutes < 60 && (
                <H3>
                  Check your email, {email}, for immediate access to the event!
                </H3>
              )}
          </Content>
          <BackgroundImage />
        </Div>
      </Wrapper>
      {friend ? (
        <Wrapper c_text positionRelative>
          <Div>
            <ContentMargins>
              <H3>Customer Information</H3>
              <H4>Billing Information</H4>
              <P>
                {firstName} {lastName}
              </P>
              <P>{email}</P>
              <P>{phoneNumber}</P>
              <H4>Payment Method</H4>
              <CreditCard>
                <CreditCardBadge
                  type={transaction?.source?.metadata?.card?.brand}
                />
                {transaction?.source?.metadata?.card?.brand?.toUpperCase()}{" "}
                ending in {transaction?.source?.metadata?.card?.last4}
              </CreditCard>
            </ContentMargins>
          </Div>
        </Wrapper>
      ) : (
        <>
          <Wrapper c_text positionRelative>
            <Div>
              <ContentMargins large>
                <H3>What Comes Next?</H3>
                <P>
                  Your reservation is booked! Please check your email for your
                  ticket - you should receive it within a few minutes. Your
                  ticket as well as future updates will be sent to{" "}
                  <span style={{ fontWeight: "bold" }}>{email}</span>. If you
                  cannot find it in your email or the email listed above is
                  incorrect, please let our customer service team know.
                </P>
                <P>
                  Your ticket will include a link to the Meet & Greet event. If
                  you accidentally delete your email, you may visit
                  artists.set.live/order-lookup to be sent a replacement ticket
                  to <span style={{ fontWeight: "bold" }}>{email}</span>.
                </P>

                <P>
                  Please review the minimum technical requirements and check
                  your audio and video ahead of time. Typically a laptop
                  connected to a broadband Wi-Fi is recommended, though many
                  smartphones and tablets are also supported.
                </P>
                <P>
                  Please be on time - the artist has allotted a certain amount
                  of time to meet fans. If you cannot make your scheduled time
                  slot or cannot get your audio and video to work, there will
                  not be a refund.
                </P>
                <Div mt_32 mb_32 dflex>
                  <Divider />
                </Div>
                {transaction?.phone && (
                  <P>
                    You will receive text updates on your request at{" "}
                    <span>{phoneNumber}</span>{" "}
                  </P>
                )}
                <Div mt_16>
                  <Footnote>
                    Message and data rates may apply. Consent is not a condition
                    of purchase. Text back STOP to cancel.
                  </Footnote>
                </Div>
              </ContentMargins>
            </Div>
          </Wrapper>
          <Wrapper c_text positionRelative>
            <ContentMargins>
              <H3>Booking Details</H3>
              <Subheader>Participant Information</Subheader>
              <P>{name}</P>
              <P>{email}</P>
              <Subheader>Meet &amp; Greet Information</Subheader>
              <P>
                {DateTime.fromJSDate(
                  selectedTimeslot?.startDate?.toDate()
                ).toFormat("DD (cccc)")}
              </P>
              <P>
                {DateTime.fromJSDate(
                  selectedTimeslot?.startDate?.toDate()
                ).toFormat("h:mm a ZZZZ")}
              </P>
            </ContentMargins>
          </Wrapper>
          <Wrapper c_text positionRelative>
            <OrderSummaryContent>
              <H3>
                <ShoppingIcon /> Order Summary
              </H3>
              <SectionHeader>Order Summary</SectionHeader>
              <div
                style={{
                  display: "flex",
                }}
              >
                <img src={event?.assets?.squareBanner?.path} width="108" />
                <MobileColumn>
                  <H2>Meet & Greet with {event?.artist}</H2>
                  <H3
                    style={{
                      fontSize: "17px",
                      flexGrow: "1",
                      textAlign: "right",
                      margin: 0,
                    }}
                  >
                    {formatCurrency(amount)}
                  </H3>
                </MobileColumn>
              </div>
              <Divider />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <H4>Subtotal</H4>
                <H4>{formatCurrency(price)}</H4>
              </div>
              <Divider color="lightest" />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <H4 style={{ color: "#596266", fontWeight: 400 }}>
                  Processing Fee
                </H4>
                <H4 style={{ color: "#596266", fontWeight: 300 }}>
                  {formatCurrency(fee)}
                </H4>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <H4>Total</H4>
                <H3 style={{ fontSize: "25px", fontWeight: 900 }}>
                  <span style={{ fontSize: "14px", fontWeight: 600 }}>USD</span>
                  {formatCurrency(amount)}
                </H3>
              </div>
            </OrderSummaryContent>
          </Wrapper>
          <Wrapper c_text positionRelative>
            <ContentMargins>
              <H3>Customer Information</H3>
              <Subheader>Contact Information</Subheader>
              <P>
                {firstName} {lastName}
              </P>
              <P>{email}</P>
              <P>{phoneNumber}</P>
              <Subheader style={{ marginTop: "32px" }}>
                Payment Method
              </Subheader>

              <CreditCard>
                <CreditCardBadge
                  type={transaction?.source?.metadata?.card?.brand}
                />
                {transaction?.source?.metadata?.card?.brand?.toUpperCase()}{" "}
                ending in {transaction?.source?.metadata?.card?.last4}
              </CreditCard>
            </ContentMargins>
          </Wrapper>
        </>
      )}
    </>
  );
};

export default Confirmation;

const Timeslot = ({ timeslot, onClick, isSelected }) => {
  const handleClick = () => {
    if (timeslot.slotsAvailable) {
      onClick(timeslot.id);
    }
  };

  const start = DateTime.fromJSDate(timeslot?.startDate.toDate());

  return (
    <TimeslotContainer onClick={handleClick} selected={isSelected}>
      <TimeslotDateTop>{start.toFormat("MMM dd")}</TimeslotDateTop>
      <TimeslotDateBottom>{start.toFormat("cccc")}</TimeslotDateBottom>
      <HR />
      <TimeslotPill>{start.toFormat("t ZZZZ")}</TimeslotPill>
      <TimeslotRemaining>
        {timeslot.slotsAvailable} spots remaining
      </TimeslotRemaining>
    </TimeslotContainer>
  );
};

const MobileColumn = styled(GridRow)`
  width: 100%;
  @media only screen and ${(props) => props.theme.media.mobile} {
    flex-direction: column;
  }
`;

const OrderSummaryContent = styled(Content)`
  padding: 0;
  padding-bottom: 24px;
  ${H2} {
    font-family: "Overpass", sans-serif;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 26px;
    padding-left: 24px;
    text-transform: none;
    margin: 0;
  }
  & > h3:first-child {
    padding: 32px 52px;
  }

  ${H4}, ${H3} {
    margin: 0;
    padding: 0;
  }
  & > div {
    padding: 24px 52px;
    ${H3} {
      font-weight: 700;
      span {
        margin-right: 4px;
      }
    }
    ${H4} {
      font-weight: 600;
    }
    &:last-child {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  @media only screen and ${(props) => props.theme.media.mobile} {
    & > h3:first-child {
      display: none;
    }
    ${SectionHeader} {
      height: initial;
    }
    ${H2} {
      font-size: 15px;
      line-height: 19px;
    }
    img {
      width: 70px;
      height: 70px;
    }
    & > div {
      padding: 16px 24px;
    }
    padding-bottom: 16px;
  }
`;

const PhoneFileContainer = styled(Div)`
  & > div {
    flex-grow: 1;
  }
  button {
    margin-left: 24px;
  }
  @media only screen and ${(props) => props.theme.media.mobile} {
    flex-direction: column;
    button {
      margin: 24px 0 0 0;
    }
  }
`;

const CreditCard = styled(P)`
  display: flex;
  align-items: center;
  svg {
    margin-right: 12px;
    width: 24px;
    height: 15px;
  }
`;

const ShoppingIcon = styled(IconShopping)`
  width: 32px;
  height: 32px;
  margin-right: 16px;
  color: #222222;
`;

const Divider = styled.span`
  display: block;
  background-color: ${(props) =>
    props.theme.palette.gray[props.color ?? "lighter"]};
  position: absolute;
  left: 0;
  height: 1px;
  width: 100%;
`;

const TimeslotDateTop = styled(P)`
  font-size: 35px;
  font-weight: 300;
  padding-bottom: 8px;
  margin: 0;
`;

const TimeslotDateBottom = styled(P)`
  font-weight: bold;
  padding-bottom: 8px;
  margin: 0;
`;

const HR = styled.div`
  height: 2px;
  width: 160px;
  border-radius: 1px;
  background-color: #596266;
`;

const TimeslotPill = styled(P)`
  border-radius: 16px;
  background-color: #f2effc;
  padding: 7px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0;
  margin: 16px 0 0 0;
`;

const TimeslotRemaining = styled(P)`
  font-size: 11px;
  font-style: italic;
  text-align: center;
  margin: 0;
`;

interface TimeSlotProps {
  selected: any;
}

const TimeslotContainer = styled.div<TimeSlotProps>`
  flex-direction: column;
  box-sizing: border-box;
  height: 166px;
  width: 192px;
  border: 1px solid #596266;
  border-radius: 4px;
  padding: 16px;
  background-color: #ffffff;
  ${(props) =>
    props?.selected &&
    `
  border: 2px solid #7C60D9;
  background-color: #F2EFFC;
  padding: 15px;
  ${TimeslotPill} {
    background-color: #BCAFE6;
  }
  `}
`;

const WarningMessage = styled(Div)`
  margin-top: 32px;
  border-top: solid 1px ${(props) => props.theme.colors.yellow20};
  border-bottom: solid 1px ${(props) => props.theme.colors.yellow20};
  background: ${(props) => props.theme.colors.yellow10};
  color: ${(props) => props.theme.colors.text};
  font-size: 17px;
  padding: 16px 60px;
  span {
    margin-left: 5px;
    color: ${(props) => props.theme.colors.linkDefault};
    text-decoration: underline;
    user-select: none;
    :hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 0;
    border-top: none;
    font-size: 15px;
    padding: 20px 5%;
  }
`;
