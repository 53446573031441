import { useState } from "react";
import firebase from "firebase";

export const useLoginLink = (sessionId): (() => Promise<void>) => {
  const getLink = () =>
    firebase.functions().httpsCallable("shoutout-loginUrl")({
      url: process.env.REACT_APP_VIDEO_URL,
      path: `${sessionId}`,
    });
  const [link, setLink] = useState(() => getLink());
  const [t, e] = useState(() => {
    console.log("asdf");
    return 2;
  });
  const handleClick = () => {
    return link
      .then((link) => {
        window.open(link?.data, "_blank");
      })
      .catch((e) => {
        setLink(getLink());
      });
  };
  return handleClick;
};
