import Div from "components/Div";
import Footer from "components/common/Footer";
import Logo from "components/common/Logo";
import {
  AppContainer,
  AppLayout,
  paddingLeftCss,
} from "components/common/layout";
import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { theme } from "theme";
import ContactInformation from "./ContactInformation";
import PaymentHeader from "./PaymentHeader";
import { Form as FForm, Formik } from "formik";
import * as yup from "yup";
import PaymentElements from "./PaymentElements";
import SummaryValues from "./SummaryValues";
import SummaryDetail, { SummaryBox } from "./SummaryDetail";
import { loadStripe } from "@stripe/stripe-js";
import { shoutoutI } from "firebase-tools";
import { generatePath, useHistory } from "react-router";
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useMediaQuery } from "hooks";
import { MobileDivider, SectionHeader } from "components/common";
import { usePaymentContext } from "components/PaymentContextProvider";
import { useAppContext } from "AppContextProvider";
import { ErrorMessageWarning } from "components/common/formikInputs";
import { Icon, P, H1, H4, Input, Label } from "notes";
import { useContext } from "react";
import { CheckoutContext } from "components/ShoutoutRequest";
import { Wrapper } from "./ContactInformation";
import firebase from "firebase";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TOKEN);

const Header = styled(Div)`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media all and ${(props) => props.theme.media.mobile} {
    justify-content: left;
    height: 60px;
    ${paddingLeftCss}
  }
`;

export const Section = styled(Div)`
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.08);
  margin-top: 25px;
  @media all and ${(props) => props.theme.media.mobile} {
    box-shadow: none;
    margin-top: 0px;
  }
`;

const Columns = styled(Div)`
  display: grid;
  grid-template-columns: 1.7fr 1fr;
  grid-gap: 65px;
  margin-bottom: 80px;
  @media all and ${(props) => props.theme.media.mobile} {
    display: flex;
    grid-gap: 0;
    flex-direction: column-reverse;
  }
`;

const ErrorMessageWrapper = styled(Div)`
  margin-top: 25px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 0;
  }
`;

const yupRequired = yup
  .string()
  .nullable()
  .required("This is a required field and cannot be blank.");
const validationSchema = yup.object().shape({
  firstName: yupRequired,
  lastName: yupRequired,
  email: yupRequired.email("This does not appear to be a valid email address."),
});

const PaymentForm = ({ timeRemaining }) => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentFormWithData timeRemaining={timeRemaining} />
    </Elements>
  );
};

const headerUI = (
  <Header>
    <Div mcontainer>
      <Div forDesktop alignCenter>
        <Logo />
      </Div>
      <Div forMobile alignCenter>
        <Logo height="18" width="85.5" />
      </Div>
    </Div>
  </Header>
);

const summaryUI = (artistContent) => (
  <EventDetails>
    <Div>
      <SummaryDetail {...artistContent} />
    </Div>
    <Div forDesktop>
      <SummaryValues />
    </Div>
  </EventDetails>
);

const formInitialValues = {};

const initPayment = async ({
  reservationId,
  timeslotId,
  firstName,
  lastName,
  email,
  amount,
  recipientName,
  recipientEmail,
  recipientPhone,
}) => {
  const handler = firebase.functions().httpsCallable("shoutout-paymentInit");
  const res = await handler({
    item: {
      id: reservationId, // I'm using the slot_id here
      label: "timeslot", // freeform
      type: "meetgreet", // must be 'meetgreet'
      amount: amount, // amount in cents as usual
      // The meta data is what allows the backend functions to process
      // the entire process correctly.
      metadata: {
        slotId: reservationId,
        sessionId: timeslotId, // this the meet_greet id
        recipientName: recipientName,
        recipientEmail: recipientEmail,
        recipientPhone: recipientPhone,
      },
    },
    // Person purchasing
    customer: {
      email: email,
      firstName: firstName,
      lastName: lastName,
    },
  }).catch((error) => {
    return { data: { success: false } };
  });
  return res;
};

// Handler for the payment process
const confirmPayment = async (
  stripe,
  paymentIntentSecret,
  paymentMethod,
  confirmHandler
) => {
  let success = false;

  const finalizePayment = firebase
    .functions()
    .httpsCallable("shoutout-paymentFinalize");

  const {
    paymentIntent,
    error: confirmError,
  } = await stripe.confirmCardPayment(
    paymentIntentSecret,
    { payment_method: paymentMethod },
    { handleActions: false }
  );

  if (confirmError) {
    // Report to the browser that the payment failed, prompting it to
    // re-show the payment interface, or show an error message and close
    // the payment interface.
    if (confirmHandler) {
      confirmHandler(false);
    }
  } else {
    // Report to the browser that the confirmation was successful, prompting
    // it to close the browser payment method collection interface.
    if (confirmHandler) {
      confirmHandler(true);
    }

    if (!paymentIntent) {
      success = false;
    } else {
      // Check if the PaymentIntent requires any actions and if so let Stripe.js
      // handle the flow. If using an API version older than "2019-02-11"
      // instead check for: `paymentIntent.status === "requires_source_action"`.
      if (paymentIntent.status === "requires_action") {
        // Let Stripe.js handle the rest of the payment flow.
        const { error } = await stripe.confirmCardPayment(paymentIntentSecret);
        if (error) {
          // The payment failed -- ask your customer for a new payment method.
          success = false;
        } else {
          // The payment has succeeded.
          success = true;
        }
      } else if (paymentIntent.status === "succeeded") {
        // The payment has succeeded.
        success = true;
      }
    }
  }

  if (success && paymentIntent) {
    await finalizePayment({ paymentIntentId: paymentIntent.id });
  }

  return success;
};

export const PaymentFormWithData = ({ timeRemaining }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest]: any = useState();
  const [error, setError] = useState(false);
  const [paymentIntentSecret, setPaymentIntentSecret] = useState();
  const {
    reservation,
    timeslot,
    name,
    email,
    phone,
    amount,
    complete,
    setComplete,
    loading,
    setLoading,
    event,
    firstName,
    lastName,
    recipientEmail,
  } = useContext(CheckoutContext);

  // Initialize payment intent
  useEffect(() => {
    if (!complete) {
      initPayment({
        reservationId: reservation?.id,
        timeslotId: timeslot,
        firstName,
        lastName,
        email,
        amount,
        recipientName: name,
        recipientEmail: recipientEmail,
        recipientPhone: phone,
      }).then((res: any) => {
        if (res.data.success) {
          setPaymentIntentSecret(res.data.token);
        }
      });
    }
  }, [amount, firstName, lastName, email, phone, name]);

  // Setup Payment Request (Apple Pay, Google Pay, etc)
  useEffect(() => {
    if (stripe && paymentIntentSecret) {
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Meet & Greet",
          amount: amount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          pr.on("paymentmethod", async (ev) => {
            setLoading(true);
            await confirmPayment(
              stripe,
              paymentIntentSecret,
              ev.paymentMethod.id,
              (success) => {
                // This is needed to close the browser dialog box
                if (!success) {
                  ev.complete("fail");
                  setError(true);
                } else {
                  setComplete(true);
                  ev.complete("success");
                  console.log("complete");
                }
                setLoading(false);
              }
            );
          });

          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe, amount, paymentIntentSecret]);

  const handleSubmit = async () => {
    if (!stripe || !elements || !paymentIntentSecret || loading) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const card = elements.getElement(CardElement);
    if (!card) {
      return;
    }
    setLoading(true);
    await confirmPayment(stripe, paymentIntentSecret, { card }, () => {}).then(
      (val) => {
        if (val) {
          setComplete(true);
          setLoading(false);
        } else {
          setLoading(false);
          setError(true);
        }
      }
    );
    setLoading(false);
  };

  const formUI = (
    <Div>
      <Formik
        initialValues={formInitialValues}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, isValid: formIsValid, touched, errors, submitForm }) => (
          <FForm>
            <Div forDesktop>
              <PaymentHeader />
            </Div>
            <Div forMobile>
              <SummaryValues />
            </Div>
            <MobileDivider />
            <ErrorMessageWrapper>
              <ErrorMessageWarning />
            </ErrorMessageWrapper>
            <SectionHeader>Payment</SectionHeader>
            <MobileDivider />
            {!complete && (
              <PaymentElements
                onSubmit={submitForm}
                paymentRequest={paymentRequest}
                displayPaymentRequestButton={paymentRequest && formIsValid}
                onPaymentRequestButtonClick={() => console.log}
                cardError={error}
                isProcessingPayment={loading}
              />
            )}
          </FForm>
        )}
      </Formik>
    </Div>
  );

  const summaryDetailContent = {
    artistImageSrc: `https://stage.set.live/assets/${event?.assets?.squareBanner?.path}`,
    artistName: event?.artist,
  };

  const shortTime = timeRemaining.minutes === 0 && timeRemaining.seconds < 61;
  const shorterTime = timeRemaining.minutes === 0 && timeRemaining.seconds < 31;

  const isLoading = !stripe || !elements;
  return (
    <AppLayout>
      {headerUI}
      <AppContainer>
        {!isLoading && (
          <Columns>
            {formUI}
            <Div dflexColumn>
              <TimeContainer justifyCenter>
                <SummaryBox shortTime={shortTime} shorterTime={shorterTime}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <StyledIcon
                      form
                      name="Clock"
                      width="24"
                      height="24"
                      fill={shortTime && "#FFF"}
                    />
                    <StyledTime>
                      {timeRemaining.minutes < 10 && "0"}
                      {timeRemaining.minutes}:
                      {timeRemaining.seconds < 10 && "0"}
                      {timeRemaining.seconds}
                    </StyledTime>
                  </div>
                  <Description>
                    Due to high demand, we can only hold your booking for up to
                    five minutes before opening it back up to others.
                  </Description>
                </SummaryBox>
              </TimeContainer>
              {summaryUI(summaryDetailContent)}
              <MobileDivider style={{ order: 2 }} />
              <MobileDivider style={{ order: 4 }} />
            </Div>
          </Columns>
        )}
      </AppContainer>
      <Footer withLogo={false} />
    </AppLayout>
  );
};

export default PaymentForm;

const StyledWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0;
  padding-bottom: 40px;
`;

const mobilePaddingCss = css`
  @media all and ${(props) => props.theme.media.mobile} {
    padding-left: 5%;
    padding-right: 5%;
  }
`;

const LeftInput = styled(Input)`
  border-radius: 4px 0 0 4px;
`;
const RightInput = styled(Input)`
  border-radius: 0 4px 4px 0;
  border-left: none;
`;

const Row = styled(Div)`
  ${mobilePaddingCss}
  ${H4} {
    font-weight: 700;
    margin: 32px 0 0 0;
    @media only screen and ${(props) => props.theme.media.mobile} {
      margin: 24px 0 0 0;
    }
  }
  ${Label} {
    margin: 16px 0 4px 0;
  }
`;

const EventDetails = styled(Div)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    order: 1;
  }
`;

const TimeContainer = styled(Div)`
  @media only screen and ${(props) => props.theme.media.mobile} {
    order: 3;
  }
`;

const StyledIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  @media only screen and ${(props) => props.theme.media.mobile} {
    width: 16px;
    height: 16px;
  }
`;

const StyledTime = styled(H1)`
  width: 87px;
  padding-left: 12px;
  font-size: 32px;
  font-weight: 800;
  line-height: 36px;
  margin: 0;
  white-space: nowrap;
  @media only screen and ${(props) => props.theme.media.mobile} {
    font-size: 25px;
    line-height: 32px;
    width: 72px;
  }
`;

const Description = styled(P)`
  color: ${(props) => props.theme.palette.gray.primary};
  font-size: 12px;
  line-height: 14px;
  margin: 8px 0 0 0;
  text-align: center;
`;
