import { H3, H4, P, Modal } from "notes";
import Video from "twilio-video";
import InApp from "detect-inapp";

export const RequirementsModal = ({ open, setOpen }) => {
  const inapp = new InApp(navigator.userAgent || navigator.vendor);
  return (
    <Modal open={open} setOpen={setOpen}>
      <H3>Minimum Requirements</H3>
      <H4 style={{ color: Video.isSupported ? "green" : "red" }}>
        {inapp.os === "ios" && inapp.isInApp && Video.isSupported ? (
          "This device may but supported, but not this browser. Please try opening this page in Safari"
        ) : (
          <>
            Your current device is{" "}
            {Video.isSupported ? (
              <span>supported</span>
            ) : (
              <span>NOT supported</span>
            )}
          </>
        )}
      </H4>
      <P>
        We currently support the following browsers and devices:
        <ul>
          <li>Google Chrome v92 (Windows/Apple OSX/Android, 2021)</li>
          <li>Mozilla Firefox v91 (Windows/Apple OSX, 2021)</li>
          <li>Microsoft Edge v92 (Windows, 2021)</li>
          <li>
            Apple Safari v14.1 (Apple OSX and iOS version 14.3+, February 2021)
          </li>
        </ul>
      </P>
    </Modal>
  );
};
