import styled, { css } from "styled-components";
import Div from "components/Div";
import { ErrorMessage, Section, sectionPadding } from "components/common";
import {
  H2,
  Input,
  Label,
  P,
  H1,
  Checkbox,
  ButtonPrimary,
  CountryPhone,
  Radio,
  Icon,
  ExpandingText,
} from "notes";
import { useContext } from "react";
import { CheckoutContext } from ".";
import { DateTime } from "luxon";
import { Formik, Form, Field, useFormikContext, useField } from "formik";

const Content = styled(Div)`
  ${sectionPadding};
  ${H1} {
    text-transform: none;
    font-family: "Overpass", sans-serif;
    font-size: 25px;
    font-weight: 800;
    line-height: 32px;
    margin: 0 0 4px;
  }
  ${Label} {
    margin-top: 24px;
    width: 100%;
    max-width: 392px;
    & > div {
      margin-top: 4px;
    }
  }
  button {
    margin-top: 40px;
  }
  @media only screen and ${(props) => props.theme.media.mobile} {
    ${H1} {
      font-size: 20px;
      line-height: 26px;
    }
    button {
      width: 100%;
    }
  }
`;

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const phoneRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

const Summary = () => {
  const {
    event,
    timeslots,
    friend,
    setFriend,
    consent,
    setConsent,
    reserveSlot,
    reservation,
    formData,
  } = useContext(CheckoutContext);

  const validate = (values) => {
    const errors: any = {};
    if (!values.name) {
      errors.name = "This is a required field and cannot be blank.";
    }
    if (!values.email) {
      errors.email = "This is a required field and cannot be blank.";
    } else {
      if (!emailRegex.test(values.email)) {
        errors.email = "This does not appear to be a valid email address.";
      }
    }
    if (friend) {
      if (!values.firstName) {
        errors.firstName = "This is a required field and cannot be blank.";
      }
      if (!values.recipientEmail) {
        errors.recipientEmail = "This is a required field and cannot be blank.";
      } else {
        if (!emailRegex.test(values.recipientEmail)) {
          errors.recipientEmail =
            "This does not appear to be a valid email address.";
        }
      }
    }
    if (!values.timeslot) {
      errors.timeslot = "This is a required field and cannot be blank.";
    } else {
      if (timeslots.find((t) => t.id === values.timeslot).slotsAvailable < 1) {
        errors.timeslot = "This time is sold out";
      }
    }
    if (values.phone && !phoneRegex.test(values.phone)) {
      errors.phone = "This does not appear to be a valid phone number.";
    }
    return errors;
  };

  return (
    <Formik initialValues={formData} validate={validate} onSubmit={reserveSlot}>
      {({ isSubmitting }) => (
        <Form>
          <Wrapper c_text positionRelative>
            <Div mcontainer>
              <Content>
                <H1>Have a One-on-One Conversation With {event.artist}!</H1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: event.checkoutDescription,
                  }}
                />
                <Label>Who will be joining the meet &amp; greet?</Label>
                <Radios dflexColumn>
                  <Radio onChange={() => setFriend(false)} checked={!friend}>
                    Me
                  </Radio>
                  <Radio onChange={() => setFriend(true)} checked={friend}>
                    Someone Else
                  </Radio>
                </Radios>
                <Label>
                  {friend ? "Participant name" : "Name"}
                  <InputFormik
                    name="name"
                    placeholder={`How ${
                      friend ? "the participant" : "you"
                    } would like to be addressed...`}
                  />
                </Label>
                <Label style={{ maxWidth: "100%" }}>
                  {event.artist} will be meeting fans during the following time
                  slots; which works best for you?
                </Label>
                <InputFormik
                  name="timeslot"
                  component={InputTimeslots}
                  timeslots={timeslots}
                />
                <TimeslotWarning />
                <Label>
                  {friend ? "Participant email address" : "Email address"}
                  <InputFormik
                    name={friend ? "recipientEmail" : "email"}
                    leftIcon={<Icon form name="Email" />}
                    placeholder="john@email.com"
                  />
                </Label>
                <Label>
                  {friend ? "Participant phone number" : "Phone number"}{" "}
                  (optional)
                </Label>
                <InputFormik
                  name="phone"
                  style={{ maxWidth: "288px" }}
                  component={CountryPhone}
                />
                {!friend && (
                  <CheckboxContainer mt_16>
                    <Checkbox checked={consent} onChange={setConsent}>
                      I agree to receive automated texts and alerts relevant to
                      my purchase. Consent is not a condition of purchase.
                    </Checkbox>
                  </CheckboxContainer>
                )}
                {friend && (
                  <>
                    <Label>
                      Your Name
                      <InputFormik
                        name="firstName"
                        placeholder="How would you like to be addressed..."
                      />
                    </Label>
                    <Label>
                      Your email address
                      <InputFormik
                        name="email"
                        leftIcon={<Icon form name="Email" />}
                        placeholder="john@email.com"
                      />
                    </Label>
                  </>
                )}
                {!reservation && (
                  <ButtonPrimary type="submit" disabled={isSubmitting}>
                    {isSubmitting ? "Loading" : "Book Now"}
                  </ButtonPrimary>
                )}
              </Content>
            </Div>
          </Wrapper>
        </Form>
      )}
    </Formik>
  );
};

const InputFormik = ({ name, component: Component = Input, ...props }) => {
  const { submitCount } = useFormikContext();
  const [{ value }, { error, touched }, { setValue }] = useField(name);
  const showError = touched && submitCount && error;
  return (
    <>
      <Component
        name={name}
        {...props}
        value={value}
        onChange={setValue}
        error={showError}
      />
      {showError && <ErrorMessage msg={showError} />}
    </>
  );
};

const InputTimeslots = ({ value, onChange, timeslots, ...props }) => {
  return (
    <TimeSlotGrid>
      {timeslots.map((t) => (
        <Timeslot
          key={t.id}
          timeslot={t}
          onClick={onChange}
          isSelected={value === t.id}
        />
      ))}
    </TimeSlotGrid>
  );
};

const TimeslotWarning = () => {
  const { values }: any = useFormikContext();
  return values?.timeslot ? (
    <WarningMessage>
      <Icon indicator name="Information" />
      Note: The selected time is only an estimate. The exact time of your call
      will depend on the number of attendees and the order of your arrival to
      the meet &amp; greet lobby. Please join the call as close to the selected
      start time as possible to guarantee your spot in line.
    </WarningMessage>
  ) : null;
};

export default Summary;

const Timeslot = ({ timeslot, onClick, isSelected }) => {
  const handleClick = () => {
    if (timeslot.slotsAvailable) {
      onClick(timeslot.id);
    }
  };

  const start = DateTime.fromJSDate(timeslot?.startDate.toDate());

  return (
    <TimeslotContainer
      onClick={handleClick}
      selected={isSelected}
      disabled={!timeslot.slotsAvailable}
    >
      <TimeslotDateTop>
        {start.toFormat("MMM dd")}{" "}
        <TimeSlotIcon centered>
          {isSelected ? (
            <IconWhite indicator name="Check" />
          ) : (
            <Icon form name="Calendar" />
          )}
        </TimeSlotIcon>
      </TimeslotDateTop>
      <TimeslotDateBottom>{start.toFormat("cccc")}</TimeslotDateBottom>
      <HR />
      <TimeslotPill>{start.toFormat("t ZZZZ")}</TimeslotPill>
      <TimeslotRemaining>
        {timeslot.slotsAvailable
          ? `${timeslot.slotsAvailable}
          ${timeslot.slotsAvailable !== 1 ? "spots " : "spot "}
        remaining`
          : "Sold Out"}
      </TimeslotRemaining>
    </TimeslotContainer>
  );
};

const Radios = styled(Div)`
  & > label {
    &:first-of-type {
      margin-top: 16px;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    margin: 9px 0;
  }
`;

const Description = styled(ExpandingText)`
  margin: 0;
  a {
    font-size: 17px;
    text-decoration: underline;
  }
  @media only screen and ${(props) => props.theme.media.mobile} {
    font-size: 15px;
    line-height: 19px;
    a {
      font-size: 15px;
      line-height: 19px;
    }
  }
`;

const IconWhite = styled(Icon)`
  path {
    fill: #ffffff;
  }
`;

const TimeSlotIcon = styled(Div)`
  height: 32px;
  width: 32px;
  background-color: #ffffff;
  border-radius: 100%;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.24);
  @media only screen and ${(props) => props.theme.media.mobile} {
    height: 24px;
    width: 24px;
    svg {
      width: 12px;
      height: 12px;
    }
  }
`;

const CheckboxContainer = styled(Div)`
  label {
    span {
      font-size: 15px;
    }
    & > div {
      top: 2px;
      transform: none;
    }
  }
`;

const TimeSlotGrid = styled(Div)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  column-gap: 12px;
  row-gap: 12px;
  margin-top: 10px;
  @media only screen and ${(props) => props.theme.media.mobile} {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    column-gap: 8px;
    row-gap: 8px;
  }
`;

const TimeslotDateTop = styled(H2)`
  display: flex;
  justify-content: space-between;
  font-size: 25px;
  font-weight: 300;
  line-height: 32px;
  margin: 0;
  @media only screen and ${(props) => props.theme.media.mobile} {
    font-size: 20px;
    line-height: 24px;
  }
`;

const TimeslotDateBottom = styled(P)`
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  margin: 0 0 12px;
  @media only screen and ${(props) => props.theme.media.mobile} {
    font-size: 12px;
    line-height: 15px;
  }
`;

const HR = styled.div`
  height: 2px;
  width: 100%;
  border-radius: 1px;
  background-color: ${(props) => props.theme.palette.gray.lighter};
`;

const TimeslotPill = styled(P)`
  border-radius: 16px;
  background-color: ${(props) => props.theme.palette.purple.lightest};
  color: ${(props) => props.theme.palette.purple.darker};
  padding: 7px 12px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  text-align: center;
  margin: 16px 0 0 0;
`;

const TimeslotRemaining = styled(P)`
  font-size: 11px;
  font-style: italic;
  text-align: center;
  margin: 0;
`;

interface TimeSlotProps {
  selected: any;
  disabled: any;
}

const TimeslotContainer = styled.div<TimeSlotProps>`
  flex-direction: column;
  box-sizing: border-box;
  border: 1px solid #596266;
  border-radius: 4px;
  padding: 16px;
  background-color: #ffffff;
  ${(props) =>
    props?.selected &&
    `
  border: 2px solid #7C60D9;
  background-color: ${props.theme.palette.purple.lightest};
  padding: 15px;
  ${TimeslotPill} {
    background-color: ${props.theme.palette.purple.lighter};
  }
  ${TimeSlotIcon} {
    background-color: ${props.theme.palette.purple.primary}
  }
  `}
  ${(props) =>
    !props?.selected &&
    !props?.disabled &&
    css`
      &:hover {
        border: 2px solid #bcafe6;
        box-shadow: 0 0 4px 0 #bcafe6;
        padding: 15px;
      }
    `};
  ${(props) =>
    props?.disabled &&
    css`
      border: 1px solid #a6aeb2;
      ${TimeslotPill} {
        background-color: #f5f5f5;
        color: #a6aeb2;
      }
      ${TimeslotDateTop}, ${TimeslotDateBottom} {
        color: #a6aeb2;
      }
      ${TimeSlotIcon} {
        display: none;
      }
      ${TimeslotRemaining} {
        color: ${(props) => props.theme.palette.gray.primary};
      }
    `};
`;

const Subheader = styled(Div)`
  color: ${(props) => props.theme.colors.disabledText};
  font-size: 15px;
  font-weight: bold;
  margin-top: 18px;
  margin-bottom: 3px;
`;

const WarningMessage = styled(Div)`
  margin-top: 16px;
  border: solid 1px ${(props) => props.theme.palette.yellow.lighter};
  border-radius: 4px;
  background: ${(props) => props.theme.palette.yellow.lightest};
  color: ${(props) => props.theme.palette.gray.primary};
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  padding: 13px 16px 14px 48px;
  position: relative;
  svg {
    position: absolute;
    top: 12px;
    left: 16px;
    path {
      fill: ${(props) => props.theme.palette.yellow.primary};
    }
  }
  @media all and ${(props) => props.theme.media.mobile} {
    padding: 12px;
    svg {
      display: none;
    }
    font-size: 10px;
    line-height: 13px;
  }
`;

const Wrapper = styled(Section)`
  padding: 0 !important;
  border: solid 1px transparent;
  margin-top: 24px;
  ${(props) =>
    props.isRequestRejected &&
    css`
      margin-top: -100px;
    `}
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 0;
    padding: 0 !important;
    border-radius: 0;
  }
`;
